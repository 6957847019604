import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../public/assets/icon/icon.png'
import _imports_1 from '../../public/assets/icon/playstore1.png'
import _imports_2 from '../../public/assets/icon/appstore1.png'


const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = {
  key: 0,
  style: {"width":"100%"}
}
const _hoisted_3 = { style: {"text-align":"left"} }
const _hoisted_4 = {
  key: 0,
  class: "calc-table"
}
const _hoisted_5 = { style: {"text-align":"right"} }
const _hoisted_6 = { style: {"text-align":"right"} }
const _hoisted_7 = { style: {"text-align":"right"} }
const _hoisted_8 = { style: {"text-align":"right"} }
const _hoisted_9 = { style: {"text-align":"right"} }
const _hoisted_10 = { style: {"text-align":"right"} }
const _hoisted_11 = { style: {"text-align":"right"} }
const _hoisted_12 = { style: {"text-align":"right"} }
const _hoisted_13 = { style: {"text-align":"right"} }
const _hoisted_14 = { style: {"text-align":"right"} }
const _hoisted_15 = { style: {"text-align":"right"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_alert = _resolveComponent("ion-alert")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_IonSelectOption = _resolveComponent("IonSelectOption")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_app_bar_title, { class: "app-bar-title" }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("Paga Ime")
                ])),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_v_menu, {
                  "open-on-hover": !_ctx.isMobile,
                  "open-on-click": _ctx.isMobile
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _mergeProps({
                      variant: "tonal",
                      color: "#2e76bc",
                      style: {"font-size":"12.5px !important","margin":"11px"}
                    }, props), {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode(" Lista e Kalkulimeve ")
                      ])),
                      _: 2
                    }, 1040)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, {
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openNetToGrossTable()))
                            }, {
                              default: _withCtx(() => _cache[12] || (_cache[12] = [
                                _createTextVNode("Neto ne Bruto")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_divider),
                        _createVNode(_component_v_list_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, {
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openGrossToNetTable()))
                            }, {
                              default: _withCtx(() => _cache[13] || (_cache[13] = [
                                _createTextVNode("Bruto ne Neto")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["open-on-hover", "open-on-click"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_main, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_content, { fullscreen: true }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "logo",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.redirectToHome()))
                  }, _cache[14] || (_cache[14] = [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: ""
                    }, null, -1)
                  ])),
                  _createVNode(_component_ion_grid, { class: "homepage-grid" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_loading, {
                        "is-open": _ctx.isOpenRef,
                        cssClass: "my-custom-class",
                        message: "Please wait..."
                      }, null, 8, ["is-open"]),
                      _createVNode(_component_ion_alert, {
                        "is-open": _ctx.isOpenAlert,
                        header: "Kujdes",
                        message: _ctx.alertTextMessage,
                        "css-class": "my-custom-class",
                        buttons: _ctx.buttons,
                        onDidDismiss: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isOpenAlert = false)),
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isOpenAlert = false))
                      }, null, 8, ["is-open", "message", "buttons"]),
                      _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            size: "12",
                            "size-sm": "8",
                            "size-md": "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_input, {
                                modelValue: _ctx.salary,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.salary) = $event)),
                                onKeypress: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isOnlyNumberAllowed($event))),
                                class: "wage-input",
                                id: "salary-text-box",
                                onClick: _cache[7] || (_cache[7] = ($event: any) => ($event.target.select()))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (
                _ctx.response &&
                _ctx.response.body &&
                _ctx.response.body[0] &&
                _ctx.response.body[0].less_than_min == true
              )
                        ? (_openBlock(), _createBlock(_component_ion_row, {
                            key: 0,
                            class: "ion-justify-content-center"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, {
                                "size-sm": "6",
                                "size-md": "4"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, [
                                    _createVNode(_component_ion_text, { color: "danger" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.response &&
                    _ctx.response.body &&
                    _ctx.response.body[0] &&
                    _ctx.response.body[0].less_than_min_msg), 1)
                                      ]),
                                      _: 1
                                    })
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_row, { class: "ion-justify-content-center custom-row-spacing" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            "size-sm": "6",
                            "size-md": "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => _cache[15] || (_cache[15] = [
                                  _createTextVNode("Kam firmosur Deklaraten e Statusit Personal ne kete kompani:")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_ion_checkbox, {
                                modelValue: _ctx.has_filled_personal_status_declaration,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.has_filled_personal_status_declaration) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_grid, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, {
                                size: "12",
                                class: "ion-text-center"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_button, {
                                    type: "button",
                                    size: "medium",
                                    color: "medium",
                                    onClick: _ctx.calculateNetToGross,
                                    style: {"font-size":"11px !important","margin":"15px 5px 15px 0px"}
                                  }, {
                                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                                      _createTextVNode("Paga Neto ne Bruto")
                                    ])),
                                    _: 1
                                  }, 8, ["onClick"]),
                                  _createVNode(_component_ion_button, {
                                    type: "button",
                                    size: "medium",
                                    color: "medium",
                                    style: {"font-size":"11px !important","margin":"15px 0px 15px 5px"},
                                    onClick: _ctx.calculateGrossToNet
                                  }, {
                                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                                      _createTextVNode("Paga Bruto ne Neto")
                                    ])),
                                    _: 1
                                  }, 8, ["onClick"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            "size-sm": "12",
                            "size-md": "4",
                            size: "12",
                            class: "ion-text-center"
                          }, {
                            default: _withCtx(() => [
                              (_ctx.response && _ctx.response.body && _ctx.response.body[0])
                                ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
                                    _createElementVNode("tr", null, [
                                      _createElementVNode("td", null, [
                                        _createElementVNode("span", _hoisted_3, [
                                          _cache[18] || (_cache[18] = _createElementVNode("strong", null, "Paga Bruto:", -1)),
                                          _createElementVNode("span", null, _toDisplayString(_ctx.response.body[0].gross_wage.toLocaleString()), 1)
                                        ])
                                      ]),
                                      _createElementVNode("td", null, [
                                        _cache[19] || (_cache[19] = _createElementVNode("span", { style: {"text-align":"left"} }, [
                                          _createElementVNode("strong", null, "Paga Neto:")
                                        ], -1)),
                                        _createTextVNode(" " + _toDisplayString(_ctx.response.body[0].net_wage.toLocaleString()), 1)
                                      ])
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            class: "ion-text-center",
                            "size-sm": "12",
                            "size-md": "4",
                            size: "12"
                          }, {
                            default: _withCtx(() => [
                              (_ctx.response && _ctx.response.body && _ctx.response.body[0])
                                ? (_openBlock(), _createElementBlock("table", _hoisted_4, [
                                    _cache[25] || (_cache[25] = _createElementVNode("thead", { class: "calc-class" }, [
                                      _createElementVNode("tr", { class: "calc-row" }, [
                                        _createElementVNode("th", { class: "calc-head" }),
                                        _createElementVNode("th", null, "Punonjësi"),
                                        _createElementVNode("th", null, "Punëdhënësi"),
                                        _createElementVNode("th", null, "Totali")
                                      ])
                                    ], -1)),
                                    _createElementVNode("tbody", null, [
                                      _createElementVNode("tr", null, [
                                        _cache[20] || (_cache[20] = _createElementVNode("td", null, [
                                          _createElementVNode("strong", null, "Tatimi mbi të ardhurat nga punësimi")
                                        ], -1)),
                                        _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.formatNumber(_ctx.response.body[0].income_tax)), 1),
                                        _cache[21] || (_cache[21] = _createElementVNode("td", { style: {"text-align":"right"} }, null, -1)),
                                        _createElementVNode("td", _hoisted_6, [
                                          _createElementVNode("strong", null, _toDisplayString(_ctx.formatNumber(_ctx.response.body[0].income_tax)), 1)
                                        ])
                                      ]),
                                      _createElementVNode("tr", null, [
                                        _cache[22] || (_cache[22] = _createElementVNode("td", null, [
                                          _createElementVNode("strong", null, "Sigurimet Shoqërore")
                                        ], -1)),
                                        _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.formatNumber(
                            _ctx.response.body[0]
                              .employee_social_security_contribution
                          )), 1),
                                        _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.formatNumber(
                            _ctx.response.body[0]
                              .employer_social_security_contribution
                          )), 1),
                                        _createElementVNode("td", _hoisted_9, [
                                          _createElementVNode("strong", null, _toDisplayString(_ctx.formatNumber(_ctx.socialContributionRowTotal)), 1)
                                        ])
                                      ]),
                                      _createElementVNode("tr", null, [
                                        _cache[23] || (_cache[23] = _createElementVNode("td", null, [
                                          _createElementVNode("strong", null, "Sigurimet Shëndetsore")
                                        ], -1)),
                                        _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.formatNumber(
                            _ctx.response.body[0]
                              .employee_health_insurance_contribution
                          )), 1),
                                        _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.formatNumber(
                            _ctx.response.body[0]
                              .employer_health_insurance_contribution
                          )), 1),
                                        _createElementVNode("td", _hoisted_12, [
                                          _createElementVNode("strong", null, _toDisplayString(_ctx.formatNumber(_ctx.healthContributionRowTotal)), 1)
                                        ])
                                      ]),
                                      _createElementVNode("tr", null, [
                                        _cache[24] || (_cache[24] = _createElementVNode("td", null, [
                                          _createElementVNode("strong", null, "Totali")
                                        ], -1)),
                                        _createElementVNode("td", _hoisted_13, [
                                          _createElementVNode("strong", null, _toDisplayString(_ctx.formatNumber(_ctx.employeeColumnTotal)), 1)
                                        ]),
                                        _createElementVNode("td", _hoisted_14, [
                                          _createElementVNode("strong", null, _toDisplayString(_ctx.formatNumber(_ctx.employeerColumnTotal)), 1)
                                        ]),
                                        _createElementVNode("td", _hoisted_15, [
                                          _createElementVNode("strong", null, _toDisplayString(_ctx.formatNumber(_ctx.total)), 1)
                                        ])
                                      ])
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _cache[34] || (_cache[34] = _createElementVNode("br", null, null, -1)),
                      _createVNode(_component_ion_row, { class: "ion-justify-content-center custom-row-spacing" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            "size-sm": "6",
                            "size-md": "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { style: {"color":"green"} }, {
                                default: _withCtx(() => _cache[26] || (_cache[26] = [
                                  _createTextVNode(" *Shpërbleheni me 1,000 lekë për çdo gabim të gjetur pas llogaritjes! ")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, { class: "ion-justify-content-center custom-row-spacing" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            "size-sm": "6",
                            "size-md": "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => _cache[27] || (_cache[27] = [
                                  _createTextVNode(" Kontaktoni në numrin: "),
                                  _createElementVNode("a", {
                                    href: "tel:+0682040640",
                                    style: {"color":"#2e76bc"}
                                  }, "0682040640", -1)
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            size: "12",
                            "size-sm": "6",
                            "size-md": "2"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_list, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_select, {
                                        interface: "popover",
                                        onIonChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handleChange($event.detail.value))),
                                        placeholder: "Zgjidhni Shtetin Per Llogaritjen e Pages"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_IonSelectOption, { value: "AL" }, {
                                            default: _withCtx(() => _cache[28] || (_cache[28] = [
                                              _createTextVNode("Shqiperi")
                                            ])),
                                            _: 1
                                          }),
                                          _createVNode(_component_IonSelectOption, { value: "XK" }, {
                                            default: _withCtx(() => _cache[29] || (_cache[29] = [
                                              _createTextVNode("Kosove")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _cache[35] || (_cache[35] = _createElementVNode("span", { style: {"display":"flex","justify-content":"center","offset":"4"} }, " Merr aplikacionin pagaime: ", -1)),
                      _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            class: "ion-text-start",
                            size: "12",
                            "size-sm": "12",
                            "size-md": "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_grid, { class: "md" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_row, {
                                    class: "ion-align-items-center md",
                                    id: "iconCol"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_col, {
                                        size: "4",
                                        class: "ion-text-center md",
                                        "size-sm": "3",
                                        "size-md": "3"
                                      }, {
                                        default: _withCtx(() => _cache[30] || (_cache[30] = [
                                          _createElementVNode("div", { class: "icon" }, [
                                            _createElementVNode("a", {
                                              href: "https://play.google.com/store/apps/details?id=net.pagaime",
                                              target: "_blank"
                                            }, [
                                              _createElementVNode("img", {
                                                src: _imports_1,
                                                alt: "",
                                                id: "icon"
                                              })
                                            ])
                                          ], -1)
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_col, {
                                        size: "4",
                                        class: "ion-text-center",
                                        "size-sm": "3",
                                        "size-md": "3"
                                      }, {
                                        default: _withCtx(() => _cache[31] || (_cache[31] = [
                                          _createElementVNode("div", { class: "icon" }, [
                                            _createElementVNode("a", {
                                              href: "https://apps.apple.com/us/app/paga-ime-kalkulo-pagen/id1620365264",
                                              target: "_blank"
                                            }, [
                                              _createElementVNode("img", {
                                                src: _imports_2,
                                                alt: ""
                                              })
                                            ])
                                          ], -1)
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _cache[33] || (_cache[33] = _createElementVNode("p", null, [
                                _createTextVNode(" Ky aplikacion u mundesua nga: "),
                                _createElementVNode("a", {
                                  href: "https://burimenjerezore.com.al/",
                                  style: {"color":"#2e76bc"}
                                }, "burimenjerezore.com.al")
                              ], -1)),
                              _createVNode(_component_ion_card_subtitle, null, {
                                default: _withCtx(() => _cache[32] || (_cache[32] = [
                                  _createTextVNode(" Elif Burime Njerëzore është eksperienca disa vjeçare e një ekipi të talentuar ekonomistësh, krah për krah me programues të zotë, derdhur mbi një program revolucionar. Një program tejet i thjeshtë, ekstremisht efikas, plotësisht përmbushës i nevojave të burimeve njerëzore. "),
                                  _createElementVNode("br", null, null, -1),
                                  _createElementVNode("br", null, null, -1),
                                  _createElementVNode("b", null, "Elif Burime Njerëzore ofron:", -1),
                                  _createElementVNode("br", null, null, -1),
                                  _createTextVNode(" Plotësimi automatik i "),
                                  _createElementVNode("a", {
                                    href: "https://www.youtube.com/watch?v=BRgctbmQmKA",
                                    style: {"color":"#2e76bc"}
                                  }, "listëpagesave nga tatimet.", -1),
                                  _createTextVNode(" Të dhënat e punonjësve, llogaritjet e pagave, listpagesat, urdhërpagesat e bankave, dokumentacioni i kërkuar nga inspektoriati i punës, lejet, raportet mjeksore madje dhe vërejtjet dhe shumë më tepër, që të gjitha të automatizuara vetëm një klik larg."),
                                  _createElementVNode("br", null, null, -1)
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_footer, { style: {"background-color":"#2e76bc"} }, {
                default: _withCtx(() => _cache[36] || (_cache[36] = [
                  _createElementVNode("div", {
                    class: "ion-text-center",
                    style: {"padding-bottom":"10px","padding-top":"10px"}
                  }, [
                    _createElementVNode("a", {
                      style: {"text-decoration":"none","color":"white"},
                      href: "https://burimenjerezore.com.al/",
                      target: "_blank"
                    }, "burimenjerezore.com.al | "),
                    _createElementVNode("a", {
                      style: {"text-decoration":"none","color":"white"},
                      href: "tel:04 45 26000"
                    }, "04 45 26000")
                  ], -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}